import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./nav"
import fix from "../constant";
import { Game } from "../components/game";

function Home() {


    return(
        <Game />
    );
}

export default Home;