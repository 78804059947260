import { useNavigate } from "react-router-dom";

function Nav() {
    let navigate = useNavigate();

    function routing(dest: any) {
        navigate(dest);
    }

    return(
        <nav className="nav">
            <ul>
                <li><a onClick={() => routing('/')}>Poker</a></li>
                <li><a onClick={() => routing('/about')}>About</a></li>
            </ul>
        </nav>
    );
}

export default Nav;