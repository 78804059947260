import React from 'react';
import { Game } from './components/game';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/home";
import About from './pages/about';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/game/:gameId' element={<Game />}></Route>
        <Route path='/about' element={<About />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
