import React from "react";
import Nav from "./nav"

function About() {

    return (
        <React.Fragment>
            <Nav />
            <div className="container">
                <div>
                    <h1>About this Poker Website</h1>
                    <hr />
                    <p>Poker is a classic card game that has been enjoyed by players around the world for centuries. It is a game of strategy, skill, and luck, where players compete to see who can create the best hand using a combination of their own cards and those that are dealt to the table.

                        This poker website is an online platform that allows players to create and join virtual poker rooms, where they can play the game without the need for physical cards or a physical table. This means that players can participate in poker games from the comfort of their own home, or from anywhere else with an internet connection.

                        To create a poker room on a website, players simply need to follow the prompts on the site to set up their own virtual table. They can invite their friends to join the room by sharing a link. Once everyone is in the room, the game can begin.

                        One of the great benefits of playing poker on a website is that players don't have to worry about shuffling and dealing the cards. The website takes care of all of this for them, allowing players to focus on strategizing and making their bets.

                        Overall, a poker website is a convenient and fun way for players to enjoy this classic game with their friends, regardless of where they are located. Whether you're a seasoned pro or a newcomer to the game, a poker website can provide endless hours of entertainment and competition.</p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default About;