import '../css/game.css';
import React, { useEffect, useState } from "react";
type Suit = "diamonds" | "clubs" | "spades" | "hearts";
type CardValue = "ace" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "jack" | "queen" | "king";
type Card = `${CardValue}_of_${Suit}`;
let cards: Card[] = [];

export function Game() {

    const suits = ["diamonds", "clubs", "spades", "hearts"] as Suit[];
    const values = ["ace", "2", "3", "4", "5", "6", "7", "8", "9", "10", "jack", "queen", "king"] as CardValue[];


    const [pickedCard, setPickedCard] = useState<string | undefined>("2");
    const [gameState, setGameState] = useState<number>(0);
    const [drawnCards, setDrawnCards] = useState<Card[]>([]);
    const initialCoins = Number(localStorage.getItem('playerCoins') || 20);
    const [coins, setCoins] = useState<number>(initialCoins);
    const [betAmount, setBetAmount] = useState<number>(1);

    function dailyCoins() {
        let lastClaim = Number(localStorage.getItem('dailyCoins'));
        if (lastClaim) {
            lastClaim = lastClaim + 14400000;
        }
        if ((lastClaim < Date.now() )|| !lastClaim) {
            setCoins(coins + 20);
            let now = new Date();
            localStorage.setItem('dailyCoins', now.getTime().toString());
        }
    }

    function generateDeck() {
        cards = [];
        for (let suit of suits) {
            for (let value of values) {
                cards.push(`${value}_of_${suit}` as Card);
            }
        }
    }
    function shuffle() {
        let a = cards;
        let x: Card[] = [];
        let y: number;
        while (a.length > 0) {
            y = Math.floor(Math.random() * a.length);
            x.push(a[y]);
            a.splice(y, 1);
        }
        cards = x;
        console.log(cards);
    }
    function takeCard() {
        if (cards.length >= 1) {
            let x = cards.pop();
            return x;
        }
    }

    const cardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
        setPickedCard(event.target.value);
    }

    const betAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBetAmount(Number(event.target.value));
    }

    function gameStateReset() {
        setGameState(0);
    }

    function play() {
        generateDeck();
        shuffle();
        setDrawnCards([]);
        setGameState(1);
    }

    function newCard() {
        if (coins >= betAmount) {
            setCoins(coins - betAmount);
            const drawnCard = takeCard();
            if (drawnCard) {
                if (drawnCards.length > 4) {
                    let updatedCards = [...drawnCards];
                    updatedCards.splice(0, 1);
                    console.log(updatedCards, drawnCard);
                    setDrawnCards([...updatedCards, drawnCard]);
                } else {
                    setDrawnCards([...drawnCards, drawnCard]);
                }
            }
            console.log(pickedCard, drawnCard);
            if (pickedCard && drawnCard?.includes(pickedCard)) {
                setGameState(2);
                setCoins(coins + betAmount * 6);
            }
        }
    }

    useEffect(() => {
        localStorage.setItem('playerCoins', String(coins));
    }, [coins]);

    if (gameState === 0) {
        return (
            <div>
                <div className='playersection'>
                    <div className='balance'>Balance: {coins}</div>
                    <div className='buttons'>
                    <button onClick={dailyCoins} className="game-button">Get Daily Coins</button>
                    </div>
                </div>
                <label htmlFor="cardSelector">Wähle eine Karte:</label>
                <select id="cardSelector" value={pickedCard} onChange={cardChange}>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="jack">Jack</option>
                    <option value="queen">Queen</option>
                    <option value="king">King</option>
                    <option value="ace">Ace</option>
                </select>
                <label>Wähle einen Einsatz:</label>
                <input type='number' value={betAmount} onChange={betAmountChange}></input>
                <button onClick={play}>Play</button>
            </div>
        );
    } else if (gameState === 1) {
        return (
            <>
                <div className='playersection'>
                    <div className='balance'>Balance: {coins}</div>
                    <div className='buttons'>
                        <button onClick={newCard} className="game-button" disabled={coins < betAmount}>Bet</button>
                        <button onClick={play} className="game-button">New Game</button>
                        <button onClick={gameStateReset} className="game-button">Change Card</button>
                    </div>
                </div>
                <div className="container">
                    <div className="cards">
                        {drawnCards.map(card => {
                            let path = `/img/SVG-cards/${card}.svg`;
                            return (
                                <img className="card" src={path} key={card} alt={card} />
                            )
                        })}
                    </div>
                </div>
            </>
        )
    } else if (gameState === 2) {
        return (
            <>
                <div className='playersection'>
                    <div className='balance'>Balance: {coins}</div>
                    <div className='buttons'>
                        <button onClick={newCard} className="game-button" disabled>Bet</button>
                        <button onClick={play} className="game-button">New Game</button>
                        <button onClick={gameStateReset} className="game-button">Change Card</button>
                    </div>
                </div>
                <div className="container">
                    <div className="cards">
                        {drawnCards.map(card => {
                            let path = `/img/SVG-cards/${card}.svg`;
                            return (
                                <img className="card" src={path} key={card} alt={card} />
                            )
                        })}
                    </div>
                    <p>Your card has been drawn!</p>
                </div>
            </>
        )
    }

    return (
        <div>hi</div>
    );
}